var loadItems = (function () {
    return {
        init: function () {
            window.page = 2;
            this.moreCamps();
        },
        moreCamps: function () {
            $('.load__more__items').off('click').on('click', function () {
                var _this = $(this);
                $.ajax({
                    'url': _this.data('url') + '?page=' + page,
                    'type': 'GET',
                    success: function (resp) {
                        _this.siblings('.row').append(resp.view);
                        if (resp.last_page <= page) {
                            _this.remove();
                        } else {
                            page++
                        }
                    }
                });
            });
        }
    }
})();

function callLightBoxMap(){
    if( $('.js__lightbox_map').length ){
        $('.js__lightbox_map').fancybox({
            'width'             : '75%',
            'height'            : '75%',
            'autoScale'         : false,
            'transitionIn'      : 'none',
            'transitionOut'     : 'none',
            'type'              : 'iframe'
        });
    }
}

function callLightBoxGallery() {
    if ($('.js__lightbox_gallery').length) {
        $('.js__lightbox_gallery').fancybox({
            type: 'image'
        });
    }
}

var bookOrder = new function (){
    this.init = function () {
        setupPeriodSelect();
        setupChildSelect();
        checkCoupon();
        initCheckCouponForm();
        changePayment();
    };
    function setupPeriodSelect () {
        var element = $('.form__select_period');
        $(element).minimalect({
            placeholder: $(element).attr('data-select-placeholder'),
            live: false,
            searchable: false,
            onchange: function(value, text){
                retrieveData(value, element.data('retrievePostUrl'), refreshPeriodData);
            }
        });
    }
    function setupChildSelect () {
        var element = $('.form__select_child');
        $(element).minimalect({
            placeholder: $(element).attr('data-select-placeholder'),
            live: false,
            searchable: false,
            onchange: function(value, text){
                retrieveData(value, element.data('retrievePostUrl'), refreshChildData);
            }
        });
    }
    var refreshPeriodData = function (data) {
        if ($.isEmptyObject(data)) {
            return;
        }
        if (data.onlinePayment) {
            $('#onlinePayment').removeClass('hidden');
        } else {
            $('#onlinePayment').addClass('hidden');
            if ($('#custom_radio_02:not(:checked)')) {
                $('#custom_radio_02').trigger('click');
            }
        }
        var periodMap = $('#period-map');
        var periodPaymentText = $('#period-payment-text');
        var periodAddress = $('#period-address');
        var periodPrice = $('#period-price');
        var periodPriceTop = $('#period-price-top');
        var periodAddressMap = $('#period-address-map');
        periodMap.attr('src', data.map);
        periodMap.parent().removeClass('hidden');
        periodPaymentText.html(data.paymentText);
        periodAddress.html(data.address);
        periodPrice.html(data.price);
        periodPriceTop.html(data.price);
        periodPrice.data('price', data.price);
        periodPrice.data('percentageAmount', data.percentageAmount);
        periodPaymentText.removeClass('hidden');
        periodAddress.removeClass('hidden');
        periodAddress.siblings('.hidden').each(function(key, elem){
            $(elem).removeClass('hidden');
        });
        if (data.map !== null) {
            periodAddressMap.attr('href', data.map);
            periodAddressMap.removeClass('hidden');
        } else {
            periodAddressMap.attr('href', '');
            if (!periodAddressMap.hasClass('hidden')) {
                periodAddressMap.addClass('hidden');
            }
        }
    };
    var refreshChildData = function (data) {
        if ($.isEmptyObject(data)) {
            return;
        }
        var childFirstName = $('input[name="child_first_name"]');
        var childLastName = $('input[name="child_last_name"]');
        var childGender = $('input[name="child_gender"]');
        var childBitrhDate = $('input[name="child_birth_date"]');
        var childExtraInfo = $('textarea[name="child_extra_info"]');
        childFirstName.val(data.child_first_name);
        childLastName.val(data.child_last_name);
        childGender.each(function(){
            if ($(this).val() == data.child_gender) {
                this.checked = true;
            } else {
                this.checked = false;
            }
        });
        childBitrhDate.val(data.child_birth_date);
        childExtraInfo.val(data.child_extra_info);
        clearExtendedMedicalInfoForm();
        if (data.show == 1) {
            $('input[name=name]').val(data.name);
            $('input[name=relationship]').val(data.relationship);
            $('input[name=address]').val(data.address);
            $('input[name=contact_mobile]').val(data.contact_mobile);
            $('input[name=doctor]').val(data.doctor);
            $('input[name=doctors_mobile]').val(data.doctors_mobile);
            $('input[name=blood_type]').val(data.blood_type);
            setCheckbox(data.vaccination, 'input[name=vaccination]');
            $('input[name=date_of_vaccination]').val(data.date_of_vaccination);
            setCheckbox(data.diabetes ,'input[name=diabetes]');
            setCheckbox(data.asthma ,'input[name=asthma]');
            setCheckbox(data.heart_problems ,'input[name=heart_problems]');
            setCheckbox(data.epilepsy ,'input[name=epilepsy]');
            setCheckbox(data.adhd ,'input[name=adhd]');
            setCheckbox(data.enuresis ,'input[name=enuresis]');
            setCheckbox(data.noctambulism ,'input[name=noctambulism]');
            $('textarea[name=exercise_of_noctambulism]').val(data.exercise_of_noctambulism);
            setCheckbox(data.diet, 'input[name=diet]');
            $('textarea[name=diet_products]').val(data.diet_products);
            setCheckbox(data.vegetarian, 'input[name=vegetarian]');
            setCheckbox(data.fish, 'input[name=fish]');
            setCheckbox(data.milk_and_eggs, 'input[name=milk_and_eggs]');
            setCheckbox(data.allergy, 'input[name=allergy]');
            $('textarea[name=pathogens_of_allergy]').val(data.pathogens_of_allergy);
            $('textarea[name=reactions_to_allergy]').val(data.reactions_to_allergy);
            $('textarea[name=medicines_for_allergy]').val(data.medicines_for_allergy);
            $('input[name=medicines]').val(data.medicines);
            $('input[name=form_of_medicine]').val(data.form_of_medicine);
            $('input[name=dosage]').val(data.dosage);
            $('textarea[name=time_and_frequency]').val(data.time_and_frequency);
            $('textarea[name=storage_method]').val(data.storage_method);
            $('textarea[name=place_of_injection]').val(data.place_of_injection);
            setCheckbox(data.junifen_or_neurofen ,'input[name=junifen_or_neurofen]');
            setCheckbox(data.dafalgan ,'input[name=dafalgan]');
            setCheckbox(data.aspirin ,'input[name=aspirin]');
            setCheckbox(data.motilium ,'input[name=motilium]');
            setCheckbox(data.medica ,'input[name=medica]');
            setCheckbox(data.hirudoid ,'input[name=hirudoid]');
            setCheckbox(data.mobilat ,'input[name=mobilat]');
            setCheckbox(data.qualiphar ,'input[name=qualiphar]');
            setCheckbox(data.flamigel ,'input[name=flamigel]');
            setCheckbox(data.touristil ,'input[name=touristil]');
            $('textarea[name=specific_issues]').val(data.specific_issues);
            $('input[name=not_participate]').val(data.not_participate);
            setCheckbox(data.swim, 'input[name=swim]');
            setCheckbox(data.swimming_certificate, 'input[name=swimming_certificate]');

            $('input[name=distance_of_swimming]').val(data.distance_of_swimming);
            showExtendedMedicalInfo();
        } else {
            showShortMedicalInfo();
        }
    };

    function setCheckbox(val, element)
    {
        if (val == 1) {
            $(element).trigger('click');
        }
    }

    function clearExtendedMedicalInfoForm()
    {
        $('.extended_medicalinfo [type=text], .extended_medicalinfo textarea').val('');
        $.each($('.extended_medicalinfo [type=checkbox]:checked'), function (key, el) {
            $(el).trigger('click');
        });
    }


    function retrieveData (hash, link, callback) {
        $.ajax({
            'url': link + '/' + hash,
            'type': 'POST',
            success: function (resp) {
                if (resp.result === true){
                    callback(resp.data)
                }
            }
        });
    }
    function initCheckCouponForm() {
        if ($('input[name=coupon]').length && $('input[name=coupon]').val().length > 0) {
            $('#sendCoupon').trigger('click');
        }
    }


    function checkCoupon() {
        $('#sendCoupon').off('click').on('click', function () {
            var coupon, route;
            //clear old remove
            removeCouponError();
            coupon = $(this).siblings('input[name=coupon]');
            route = coupon.data('route');
            var type = 'online';
            if ($('#custom_radio_02:checked').length > 0) {
                type = 'offline';
            }
            $.ajax({
                url:     route,
                type:    'post',
                data:    {
                    camp:   $('input[name=camp_hash]').val(),
                    period: $('.form__select_period').val(),
                    code:   coupon.val(),
                    child_first_name: $('input[name="child_first_name"]').val(),
                    child_last_name: $('input[name="child_last_name"]').val(),
                    email: $('input[name=email]').val(),
                    type_payment: type
                },
                success: function (resp) {
                    if (resp.status) {
                        var periodPrice = $('#period-price');
                        var actualPrice = resp.total;
                        if (type == 'online') {
                            actualPrice = parseFloat(resp.total) + resp.percentageAmount;
                        }
                        periodPrice.html('€' + actualPrice);
                        $('#period-price').data('price', resp.total);
                        $('#period-price').data('percentageAmount', resp.percentageAmount);
                        $('#period-price').data('couponAmount', resp.coupon_amount);
                        fillCouponInfo(resp.code, resp.end_date, resp.coupon_amount);
                        removeCouponError();
                    } else {
                        addCouponError(resp.message);
                    }
                },
                error: function (errors) {
                    $.each(errors.responseJSON, function (index, error) {
                        addCouponError(error);
                    });
                }
            });
        });
    }

    function changePayment() {
        $('input[name=payment__method]').off('change').on('change', function () {
            console.log(123);
            var _this = $(this);
            var price = $('#period-price').data('price');
            var percentageAmount = $('#period-price').data('percentageAmount');
            if ($('#custom_radio_02:checked').length > 0) {
                $('#period-price').html('€' + price);
            } else {
                $('#period-price').html('€' + (parseFloat(price) + parseFloat(percentageAmount)));
            }
        });
    }

    function fillCouponInfo(code, endDate, price) {
        $('#couponId').html(code);
        $('#couponEndDate').html(endDate);
        $('#couponPrice').html(price);
        $('#couponInfo').removeClass('hidden');
        $('#couponForm').addClass('hidden');
        removeCoupon();
    }

    function hideCouponInfo() {
        $('#couponForm').removeClass('hidden');
        $('#couponInfo').addClass('hidden');
        $('#couponId').empty();
        $('#couponEndDate').empty();
        $('#couponPrice').empty();
        $('input[name=coupon]').val('');
        var containerPeriodPrice = $('#period-price');
        var price = parseFloat(containerPeriodPrice.data('price'));
        var couponAmount = parseFloat(containerPeriodPrice.data('couponAmount'));
        var amount = price + couponAmount;
        $('#period-price').data('price', amount);
        $.ajax({
            'url': $('#period-price').data('percentageUrl'),
            type: 'get',
            data: {
                amount : amount
            },
            success: function(resp) {
                var percentageAmount = parseFloat(resp.amount);
                $('#period-price').data('percentageAmount', percentageAmount);
                if ($('#custom_radio_01:checked').length > 0) {
                    amount += percentageAmount;
                }
                $('#period-price').html('€' + amount);
            }
        });
    }

    function removeCoupon() {
        $('#couponInfo .del').off('click').on('click', function () {
            hideCouponInfo();
        });
    }

    function removeCouponError() {
        var errorContainer = $('#couponForm .alert');
        errorContainer.addClass('hidden');
        errorContainer.find('ul').empty()
    }

    function addCouponError(message) {
        var errorContainer = $('#couponForm .alert');
        errorContainer.removeClass('hidden');
        errorContainer.find('ul').prepend('<li style="list-style-type: none;"><i class="fa fa-warning"></i> ' + message + '</li>');
    }
};

function initMask() {
    $(".date").inputmask("date");
    $(".bank_number").inputmask("BE99 9999 9999 9999");
}

var search = new function (){
    this.init = function () {
        setupRegionSelect();
    };

    function setupRegionSelect () {
        var element = $('.region-select');
        $(element).minimalect({
            placeholder: $(element).attr('data-select-placeholder'),
            live: false,
            searchable: false,
            onchange: function(value, text){
                getLocations(value, element.data('url'), locationsReplace);
            }
        });
    }

    function getLocations(regionCode, url, callback) {
        $.ajax({
            url: url,
            type: 'POST',
            data: {
                region_code: regionCode
            },
            success: function (resp) {
                callback(resp)
            }
        });
    }

    function locationsReplace(data)
    {
        var element = $('.location-select');
        element.replaceOptions(data);
        $('.location-select').change();
    }
};
var cabinet = new function (){
    var booking, child;
    this.init = function () {
        hangEventOfRemoveBooking();
        hangEventOfRemoveChild();
    };

    function hangEventOfRemoveBooking() {
        $('.remove-booking').off('click').on('click', function () {
            booking = $(this);
            fancyConfirm(booking.data('confirm-mess'), deleteBooking);
        });
    }
    function hangEventOfRemoveChild() {
        $('.delete-child').off('click').on('click', function () {
            child = $(this);
            fancyConfirm(child.data('confirm-mess'), deleteChild);
        });
    }

    function deleteBooking()
    {
        var url = booking.data('href');
        $.ajax({
            url: url,
            type: 'get',
            success: function (resp) {
                if (resp.status) {
                    booking.siblings('.status-btn').html(booking.data('cancel-title'));
                    booking.remove();
                }
                fancy(resp.message);
            }
        });
    }

    function deleteChild()
    {
        var url = child.data('href');
        $.ajax({
            url: url,
            type: 'delete',
            success: function (resp) {
                if (resp.status) {
                    child.parents('.children_info_item:first').remove();
                }
                fancy(resp.message);
            }
        });
    }

    function fancy(message) {
        $('.fancy-data').html(message);
        $('.fancy-data').fancybox({
            'transitionIn':       'elastic',
            'transitionOut':      'elastic',
            'speedIn':            600,
            'speedOut':           200,
            'overlayShow':        true,
            'minWidth':           200,
            'minHeight':          50,
            'hideOnContentClick': true
        });
        $('.fancy-data').trigger('click');
    }

    function fancyConfirm(msg, callback) {
        $.fancybox("#confirm",{
            modal: true,
            beforeShow: function() {
                $(".fancy-title").html(msg);
            },
            afterShow: function() {
                $(".confirm").on("click", function(event){
                    if($(event.target).is(".yes")){
                        ret = true;
                    } else if ($(event.target).is(".no")){
                        ret = false;
                    }
                    $.fancybox.close();
                });
            },
            afterClose: function() {
                if (ret) {
                    callback.call();
                }
            }
        });
    }
};

var countrySelect = new function (){
    this.init = function () {
        setupCountrySelect();
    };
    function setupCountrySelect () {
        var element = $('.form__select_country');
        $(element).minimalect({
            placeholder: $(element).attr('data-select-placeholder'),
            live: false,
            searchable: true,
        });
    }
};

function showPeriods() {
    var hash = location.hash;
    if (hash == '#all-periods') {
        $('.free__dates').trigger('click');
    }
}

function extendedMedicalInfo() {
    $('.extendedMedicalInfo').off('click').on('click', function() {
        showExtendedMedicalInfo();
    });
}

function showExtendedMedicalInfo() {
    var extendedMedicalInfo = $('.extended_medicalinfo');
    if (extendedMedicalInfo.hasClass('hidden')) {
        extendedMedicalInfo.removeClass('hidden');
        $('input[name=extended_medical_info]').val(1);
        $('.extendedMedicalInfo').removeClass('btn--lighten');
        closeShortMedicalInfo();
    }
}

function shortMedicalInfo() {
    $('.shortMedicalInfo').off('click').on('click', function() {
        showShortMedicalInfo();
    });
}

function showShortMedicalInfo() {
    var shortMedicalInfo = $('.childExtraInfo').parent('div');
    if (shortMedicalInfo.hasClass('hidden')) {
        $('input[name=extended_medical_info]').val(0);
        shortMedicalInfo.removeClass('hidden');
        $('.shortMedicalInfo').removeClass('btn--lighten');
        closeExtendedMedicalInfo();
    }
}

function closeExtendedMedicalInfo() {
    $('.extendedMedicalInfo').addClass('btn--lighten');
    $('.extended_medicalinfo').addClass('hidden');
}

function closeShortMedicalInfo() {
    $('.shortMedicalInfo').addClass('btn--lighten');
    $('.childExtraInfo').parent('div').addClass('hidden');
}

function healthServiceSelect() {
    var element = $('.health_service');
    $(element).minimalect({
        placeholder: $(element).attr('data-select-placeholder'),
        live: false,
        searchable: false
    });
}

var healthServicePopup = new function()
{
    // Get the modal
    var modal = document.getElementById('myModal');

    if (modal !== null) {
        var errorContainer = $('#myModal').find('.alert');
        var ul = errorContainer.find('ul');
        // When the user clicks on the links, open the modal
        $('.showPopup').off('click').on('click', function(e) {
            e.preventDefault();
            errorContainer.addClass('hidden');
            ul.empty();
            modal.style.display = "table";
            $('.saveHealthService').data('redirectUrl', $(this).prop('href'));
        });

        // When the user clicks on <span> (x), close the modal
        $('span.close').off().on('click', function () {
            closeModal();
        });

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        };

        $('.saveHealthService').off('click').on('click', function () {
            $.ajax({
                url: $(this).parents('form').prop('action'),
                type: 'post',
                data: $(this).parents('form').serialize(),
                success: function(response) {
                    if (response.status == 'success') {
                        closeModal();
                        window.location.href = $('.saveHealthService').data('redirectUrl');
                    }
                },
                error: function (errors) {
                    errorContainer.removeClass('hidden');
                    ul.empty();
                    $.each(errors.responseJSON, function (index, error) {
                        ul.prepend('<li style="list-style-type: none;"><i class="fa fa-warning"></i> ' + error + '</li>');
                    });
                }
            });
        });

        function closeModal() {
            modal.style.display = "none";
        }
    }
};

$(document).ready(function () {
    loadItems.init();
    callLightBoxMap();
    callLightBoxGallery();
    bookOrder.init();
    initMask();
    search.init();
    $.datepicker.setDefaults( $.datepicker.regional[ window.currentLocale ] );
    cabinet.init();
    countrySelect.init();
    showPeriods();
    extendedMedicalInfo();
    shortMedicalInfo();
    healthServiceSelect();
    healthServicePopup;
});

(function ($, window) {
    $.fn.replaceOptions = function (options) {
        var self, $option;
        this.empty();
        self = this;
        $.each(options, function (index, option) {
            $option = $("<option></option>")
                .attr("value", index)
                .text(option);
            self.append($option);
        });
    };
})(jQuery, window);

$(".payment__section button.btn-sbm").click(function () {
    $("#bookOrder").submit();
    $(this).prop('disabled', true);
    $(this).html('<span class="fa fa-spinner fa-spin"></span>');
});

$("#phone-input").closest("form").submit(function () {
    $("#mobile-hidden").val($("#phone-input").intlTelInput("getNumber"));
});
$("#phone-input").intlTelInput({
    preferredCountries: ['be', 'nl'],
    initialCountry: 'be',
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.13/js/utils.js',
    nationalMode: true
});