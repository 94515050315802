$(document).ready(function(){
  detectOutdatedBrowser();
  customSelects();
  datepicker();
  replaceSVG();
  showMoreDate();
  callLightBox();
})

function detectOutdatedBrowser(){
  outdatedBrowser({
      bgColor: '#f25648',
      color: '#ffffff',
      lowerThan: 'transform',
      languagePath: ''
  })
}

function customSelects(){
  if( $('.form__select').length ){
    $('.form__select').each(function(){
      var thisPlaceholder = $(this).attr('data-select-placeholder');
      $(this).minimalect({
          placeholder: thisPlaceholder,
          searchable: false
      });
    })
  }
}

function datepicker(){
    if( $('.site_datepicker').length ){
        $('.site_datepicker').datepicker(
            {
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                yearRange: 'c-20:c' 
            }
        );
    }
}

function replaceSVG(){
  if (!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
  }
}

function showMoreDate(){
  $('body').on('click', '.free__dates, .free__date--more', function(){
    $('.free__popover').slideToggle();
    var dest = $('.inner__content').offset().top;
    $('html, body').animate({ scrollTop : dest });
    return false;
  });
  $('body').on('click', '.free__popover--close', function(){
    $('.free__popover').slideUp();
    var dest = $('.inner__content').offset().top;
    $('html, body').animate({ scrollTop : dest });
    return false;
  });
}

function callLightBox(){
  if( $('.js__lightbox').length ){
    $('.js__lightbox').fancybox();
  }
}
